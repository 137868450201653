import React, { memo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

const rootStyles = makeStyles((theme) => ({
  menu: {
    padding: 0
  },
  title: {
    fontStyle: 'italic',
    fontWeight: '100',
    fontVariant: 'small-caps',
    flex: 1,
    padding: '0.37em 0',
    color: theme.palette.text.primary,
  },
  logo: {
    height: 20,
    verticalAlign: 'middle',
    marginRight: 34,
    marginLeft: 1,
    filter: (props) => props.lights ? 'brightness(0.5)' : 'none',
  }
}))

export default memo(({ setMobileOpen }) => {
  const location = useLocation()
  const root = rootStyles()
  const lights = window.localStorage.getItem('lights') === 'on'
  const pathname = location.pathname.split('/')
  const isSettings = pathname.length > 2 && pathname[2] === 'settings'
  const activeLink = lights ? '#efefef' : 'rgb(88, 88, 88)'
  // https://reacttraining.com/react-router/web/api/NavLink
  // this trigers a re-render on each time tick
  // disabling for now
  const activestyle = {
    background: activeLink
  }
  return <List className={root.menu}>
    <ListItem>
      <Typography className={root.title} variant="h5" component="h3">
        <img alt="Channel - ivs" className={root.logo} src="/logo.png"></img>Channel - ivs
        </Typography>
    </ListItem>
    <Divider />
    <ListItem button
      key={'Dashboard'}
      component={NavLink}
      onClick={() => setMobileOpen(false)}
      {...{
        disableTouchRipple: true,
        activestyle,
        to: '/dashboard',
        selected: pathname.length === 2
      }}>
      <ListItemIcon>{<Icon>dashboard</Icon>}</ListItemIcon>
      <ListItemText primary={'Dashboard'} />
    </ListItem>
    <Divider />
    <ListItem button
      key={'Settings'}
      component={NavLink}
      onClick={() => setMobileOpen(false)}
      {...{
        disableTouchRipple: true,
        to: '/dashboard/settings',
        activestyle,
        selected: isSettings
      }}>
      <ListItemIcon>{<Icon>settings</Icon>}</ListItemIcon>
      <ListItemText primary={'Settings'} />
    </ListItem>
    <Divider />
  </List>
})