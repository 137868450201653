import React, { useEffect, useRef, Fragment, memo } from 'react'
import Button from '@material-ui/core/Button'

const Ivs = memo(({ url, loading, loadingStyle, style, className }) => {

    const videoRef = useRef()

    useEffect(() => {
        console.log(window.IVSPlayer)
        const player = window.IVSPlayer.create()
        if (window.IVSPlayer && window.IVSPlayer.isPlayerSupported && videoRef.current) {
            player.attachHTMLVideoElement(videoRef.current)
            player.load(url)
            // player.play()
        }

        return () => {
            player.delete()
        }
    }, [videoRef, url])

    const goFullScreen = () => {
        console.log(videoRef.current.webkitRequestFullScreen)
        if (videoRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen()
        } else if (videoRef.current.webkitRequestFullScreen) {
            console.log("fullscreen")
            videoRef.current.webkitRequestFullScreen()
        }
    }

    return <Fragment>
        {loading && <p style={loadingStyle}>loading...</p>}
        <video controls autoPlay={false} muted={false} style={style} className={className} ref={videoRef}></video>
        <Button onClick={goFullScreen}>fullscreen</Button>
    </Fragment>
})

export default Ivs