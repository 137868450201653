import React, { useState } from 'react'
import moment from 'moment'
import { useSubscribe } from '../api'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import AppBar from '@material-ui/core/AppBar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Table from '../table'
import { translatePrize, translateBonus, translatePrizeList, translateBonusList, exportToCsv } from './Constants'

const TabContainer = ({ children, dir }) => (<Typography component="div" dir={dir}>
  {children}
</Typography>)

const rootStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    background: 'transparent',
    overflowY: 'auto',
  },
  tabRoot: {
    display: 'flex',
    flex: '1 1'
  },
  list: {
    padding: 0,
    background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6'
  },
  listHeader: {
    background: theme.palette.primary.main
  },
  listHeaderText: {
    overflowWrap: 'break-word'
  },
  searchHeader: {
    background: (props) => props.lights ? '#ececec' : '#3e3e3e',
    justifyContent: 'space-between'
  },
  listItem: {
    display: 'grid',
    padding: 0,
  },
  empty: {
    padding: '1em',
    fontSize: '0.8em'
  }
}))

function glob(pattern, input) {
    if (pattern === "" || pattern.length < 2) {
        return true
    }

    if (input.name.toLowerCase().indexOf(pattern.toLowerCase()) >= 0) {
        return true
    }

    if (input.email.toLowerCase().indexOf(pattern.toLowerCase()) >= 0) {
        return true
    }

    if (input["prize code"].toLowerCase().indexOf(pattern.toLowerCase()) >= 0) {
        return true
    }

    if (input.mobile.indexOf(pattern) >= 0) {
        return true
    }

    return false
}

function generateGamesRows(games) {
  const header = ["name", "email", "mobile", "prize code", "prize", "bonus", "isEU", "playedOn", "redeemedOn", "staffName"]
  let rows = [header]
  for (const game of games) {
    rows.push([game.name, game.email, game.mobile, game["prize code"], game.prize, game.bonus, game.isEU, game.played, game.redeemed, game.staffName])
  }

  return rows
}

const Boxes = ({ authorize }) => {
  const role = window.localStorage.getItem('role')
  const lights = window.localStorage.getItem('lights') === 'on'
  // socket
  const [boxes, socket] = useSubscribe('games/*', authorize)
  const active = socket && socket.readyState === WebSocket.OPEN

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const styles = rootStyles({ active, lights })

  // tabs
  const [tab, setTab] = useState(0)

  function changeTab(index) {
    setTab(index)
  }

  const boxesMap = boxes ? boxes.map(box => ({
    name: box.data.FirstName + " " + box.data.LastName,
    email: box.data.Mail,
    mobile: box.data.Mobile,
    "prize code": box.data.PrizeCode,
    prize: translatePrizeList(box.data.Prize),
    bonus: translateBonusList(box.data.Bonus),
    isEU: box.data.IsEU ? "yes" : "no",
    played: moment.unix(box.created / 1000000000).format('DD/MM/YY'),
    redeemed: box.data.Redeemed ? moment.unix(box.data.Redeemed / 1000000000).format('DD/MM/YY') : '-',
    staffName: box.data.StaffName ? box.data.StaffName : "-",
    index: box.index
  })) : null

  const storedSearch = window.localStorage.getItem('storage:search')
  const [search, setSearch] = useState(!storedSearch ? '' : storedSearch)
  const filtered = boxesMap ? boxesMap.filter(box => glob(search, box)) : null

  console.log("games")

  return <Paper className={styles.root} elevation={0}>
    {(() => role === 'admin' || role === 'root' ? [
      <AppBar key="boxesTabsHeader" position="sticky" color="default">
        <Tabs
          value={tab}
          onChange={(_e, index) => {
            if (index === 0) {
              changeTab(index)
            }
          }}
          indicatorColor="primary"
          textColor="primary"
          variant={mobile ? 'fullWidth' : 'standard'}
        >
          <Tab disableTouchRipple label="Games" />
          {(boxesMap && boxesMap.length > 0) && <Tab disableTouchRipple label="Download CSV" onClick={(e) => {
            exportToCsv("gamesPlayed.csv", generateGamesRows(boxes.sort((a, b) => a.created - b.created).map(box => ({
              name: box.data.FirstName + " " + box.data.LastName,
              email: box.data.Mail,
              mobile: box.data.Mobile,
              "prize code": box.data.PrizeCode,
              prize: translatePrize(box.data.Prize),
              bonus: translateBonus(box.data.Bonus),
              isEU: box.data.IsEU ? "yes" : "no",
              played: moment.unix(box.created / 1000000000).format('DD/MM/YY'),
              redeemed: box.data.Redeemed ? moment.unix(box.data.Redeemed / 1000000000).format('DD/MM/YY') : '-',
              staffName: box.data.StaffName ? box.data.StaffName : "-",
              index: box.index
            }))))
            e.stopPropagation()
            e.preventDefault()
            return 0
          }} />}
        </Tabs>
        <List className={styles.list}
          component="nav">
          <ListItem className={styles.listHeader}>
            {(() => tab === 0 ?
              <ListItemText className={styles.listHeaderText} primary={'Games played'} /> :
              <ListItemText className={styles.listHeaderText} primary={'Game details'} />)()}
          </ListItem>
        </List>
        <List className={styles.list} component="nav">
        <ListItem className={styles.searchHeader}>
          <TextField
            id="search"
            label="Search"
            className={styles.searchInput}
            value={search}
            margin="normal"
            fullWidth
            autoFocus
            InputLabelProps={{
              classes: {
                outlined: styles.searchInputLabel
              }
            }}
            InputProps={{
              classes: {
                input: styles.searchInputRoot
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value)
              window.localStorage.setItem('storage:search', e.target.value)
            }}
            variant="outlined"
          />
        </ListItem>
      </List>
        {(!boxes || !active) && <LinearProgress />}
      </AppBar>,
      <SwipeableViews key="boxesTabs"
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tab}
        onChangeIndex={changeTab}
        containerStyle={{
          flex: '1 1 0%',
          overflowX: 'auto',
          display: 'flex',
          paddingBottom: tab === 0 ? 55 : 0
        }}
        className={styles.tabRoot}
      >
        <TabContainer dir={theme.direction}>
          {(filtered && filtered.length > 0) && <Table rows={filtered}
            pagination
            hiddenMobileFields={['created', 'updated']}
            hiddenFields={['index']}
            link={(row) => '/dashboard/game/' + row['index']} />}
          {(filtered && filtered.length === 0) && <Typography className={styles.empty} component="p">There are no games</Typography>}
        </TabContainer>
      </SwipeableViews>
    ] : [(filtered && filtered.length > 0) && <Table key="boxesTable"
      rows={filtered}
      pagination
      hiddenMobileFields={['created', 'updated']}
      hiddenFields={['index']}
      link={(row) => '/dashboard/game/' + row['index']} />,
    (filtered && filtered.length === 0) && <Typography key="boxesEmpty" className={styles.empty} component="p">There are no games</Typography>
      ])()}
  </Paper>
}

export default Boxes