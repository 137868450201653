import React from 'react'
import Paper from '@material-ui/core/Paper'
import Ivs from '../ivs/Ivs'
import { makeStyles } from '@material-ui/core/styles'
// import useWindowSize from '../WindowSize'

const rootStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'auto',
    }
}))

// https://github.com/aws-samples/amazon-ivs-feed-web-demo/blob/master/public/feed.json

const Player = () => {
    // const [width, , resizing] = useWindowSize()
    const styles = rootStyles()
    // const widthBreak = 1000

    return (<Paper className={styles.root} elevation={1}>
        <Ivs url={'https://3730edd0b45d.ap-northeast-2.playback.live-video.net/api/video/v1/ap-northeast-2.057928903638.channel.A8rLRDsKV29z.m3u8'}
            style={{
                marginTop: '0',
                maxWidth: '-webkit-fill-available',
                maxHeight: '-webkit-fill-available'
            }}
        ></Ivs>
    </Paper>)
}

export default Player